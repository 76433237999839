import styled from "styled-components/macro";
import { Icon } from "assets/Icons_";
import { useState } from "react";

import { fn3 } from "utilities/utility.misc";
import { remove, replace } from "utilities/utility.filter";
import { applyfilters } from "utilities/utility.filter";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useGet, setFocus, getFocus } from "state/jotai";

export const Dropdown = ({ Label, PossibleValues, active, setFilters }) => {
  const { pin, pid } = useGet(active_project);
  const { Units, ...rest } =
    getFocus(_project, "unitsraw")?.[pin]?.[pid] ?? "err";
  const setUnits = setFocus(_project, "units");

  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const [foldout, setFoldout] = useState(false);
  const glarr = { color: clr.icons, icon: "globe_arrow", size: 8 };

  return (
    <Wrap style={{ width: "100%" }}>
      <Box clr={clr} onClick={() => setFoldout(!foldout)}>
        {active?.dropdown
          ? active?.dropdown
          : `${
              buttonsLabel?.filter(
                btn => btn?.UILabelProperty === "AlleBoligtyper"
              )[0]?.Label
            }`}
        <Icon
          {...glarr}
          wrap={{
            position: "relative",
            top: 6,
            left: 3,
            transform: foldout ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </Box>
      <Foldout state={foldout}>
        {PossibleValues.map(({ ValueProperty, Value }, j) => {
          const selected = active?.dropdown == Value;

          const onClick = () => {
            const clear =
              Value ==
              `${
                buttonsLabel?.filter(
                  btn => btn?.UILabelProperty === "AlleBoligtyper"
                )[0]?.Label
              }`;

            const data = { ...active, dropdown: clear ? "" : Value };

            // APPLY FILTERS TO UNITS STATE
            const units_ = applyfilters(Units, data);
            const units = { ...rest, Units: units_ };

            // SET activefilters state variable
            setFilters(a => fn3(a, pin, pid, data));
            setUnits(a => fn3(a, pin, pid, units));

            setFoldout(false);
          };

          return (
            <Option
              key={j}
              clr={clr}
              selected={selected}
              onClick={onClick}
              children={Value}
            />
          );
        })}
      </Foldout>
    </Wrap>
  );
};

const Wrap = styled.div`
  margin: 5px 75px 5px 80px;
  cursor: pointer;
  max-width: 275px;
`;

const Box = styled.div`
  background-color: ${({ clr }) => `${clr?.buttons_text}1a`};
  width: 100%;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: ${({ clr }) => `${clr?.buttons_text}`};
`;

const Foldout = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ state }) => (state ? "unset" : 0)};
  overflow: hidden;
  transition: height 0.3s linear;
`;

const Option = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  background-color: ${({ clr, selected }) =>
    selected ? `${clr?.buttons_text}50` : `${clr?.buttons_text}10`};
  :hover {
    background-color: ${({ clr }) => `${clr?.buttons_text}20`};
  }
  color: ${({ clr }) => `${clr?.buttons_text}`};
`;
