import styled, { css }             from 'styled-components/macro'
import { _project }                from 'state/store.projects'
import { vis_overlay }             from 'state/store.global'
import { active_unit }             from 'state/store.global'
import { useGet, useAtom }         from 'state/jotai'
import { useUnit, usePick }        from 'components/Isometry/Isometry.utilities'
import { motion, AnimatePresence } from 'framer-motion'
import { open_drawer }             from 'state/actions'

export const Svg = ({ svg, percent, units }) => {

  const vbox = svg?.attributes?.viewBox
  const blur = Math.abs(percent-100)/3
  const opac = percent/100
  const draw = useAtom(open_drawer)[1]
  const geta = useAtom(active_unit)[0]
  const seta = useAtom(active_unit)[1]
  const show = useGet(vis_overlay)
  const pick = usePick()
  const unit = useUnit()

  const container = {
    hidden: { opacity:0 },
    show: { 
      opacity: 1,
      // duration: 0.1,
      // transition: { staggerChildren: 0.005, staggerDirection:-1 }
     },
  }

  const item = {
    hidden: { opacity:0 },
    show: { opacity:1 },
  }
  
  return (
    <AnimatePresence>
    {
      units && show && percent == 67 && (
        <svg viewBox={vbox} style={{position:'absolute'}} variants={container}>
        {/* <motion.svg viewBox={vbox} style={{position:'absolute'}} variants={container} initial="hidden" animate="show"> */}
          { 
            svg?.children?.map( ({attributes:{id,d}}) => {
              const ID      = id?.replace(/nr/g,'')
              const address = unit(ID,'Adresse')
              const sID     = unit(ID,'UnitStatusID')
              const sName   = unit(ID,'UnitStatusName')
              const color   = unit(ID,'UnitColor','f')
              const picked  = pick(ID)
              const UnitID  = unit(ID,'UnitID')
              const Unit    = unit(ID,0,'i')
              const isActive = geta.UnitID === UnitID
              const onClick = () => { 
              
                if ( sID == 40 ) { return }

                // SET ACTIVE UNIT
                seta( Unit )

                draw('detail')

                console.log( 
                  `__________________           \n` +
                  `| ID         : ${ID}         \n` +
                  `| UnitID     : ${UnitID}     \n` + 
                  `| addresse   : ${address}    \n` +
                  `| statusID   : ${sID}        \n` +
                  `| statusName : ${sName}      \n` +
                  `| color      : ${color}      \n` + 
                  `| picked     : ${picked}     \n` + 
                  `| Unit       : ${Unit}`
                )


              }
              const style = {filter:`blue(${blur}px)`,opacity:opac}
              const key = d
              const path = { d, id, key, style, onClick, sID, sName, color, picked, active:isActive }
              {/* if ( !d.includes('M0') ) return <Path layout as={motion.path} {...path} variants={item}/> */}
              if ( !d.includes('M0') ) return <Path {...path}/>
          })}
        </svg>
      )
    }
    </AnimatePresence>
  )
}
const Path = styled.path( ({sID,color,picked,active}) => css`
  cursor: ${ sID == 40 ? 'not-allowed' : 'pointer' };
  fill: ${ (() => {
    if ( active ) return '#FFFFFF90'
    if ( picked ) return '#FF00FF'
    if ( color.value == 'err' ) {
      if ( sID == 10 ) return '#00ff0080'
      if ( sID == 20 ) return '#ffff0080'
      if ( sID == 30 ) return '#0000ff80'
      if ( sID == 40 ) return '#ff000080'
    }
    return ( typeof color === 'string' ) 
      ? color + '80' 
      : '#00000080'
  })() };
  stroke: #000080;
  stroke-width: 1px;
  :hover { fill: #ffffff80; }
`)

