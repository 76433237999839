import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { comparesData, compares, active_project } from "state/store.global";
import { getFocus, useGet, useSet } from "state/jotai";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css/free-mode";
import { Box, Divider, Grid, Stack, useMediaQuery } from "@mui/material";
import CompareUnitCard from "components/Compare/CompareUnitCard";
import { _project } from "state/store.projects";
import { ButtonCompare, ButtonFavorite, ButtonShare } from "components/Unit";
import { PoweredBy } from "components/PoweredBy/PoweredBy";

export const Compare = () => {
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const data = useGet(comparesData);
  const comps = useGet(compares);
  const setComps = useSet(compares);
  const units = data?.Units ?? [];
  const x = comparePropertyNames(units);
  const navigateTo = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "calc(100vh - 123px)",
          justifyContent: "space-between",
        }}
      >
        {!units?.length ? (
          <Box sx={{ textAlign: "center", mt: 5 }}>
            There is no available unit to compare
          </Box>
        ) : (
          <Box
            sx={{
              minWidth: { lg: 1200, md: 1000, sm: 750, xs: 500 },
              overflowX: "auto",
            }}
          >
            <Swiper {...SwiperProps}>
              {units.map((i, j) => {
                const salesType = i.Data.SalesTypeName.Value;
                const status = i.Data.UnitStatusName.UnformattedValue;
                const floorplan = i.Gallery?.Floorplan?.Url;
                const idx = j < i.Gallery?.ListOfPhotos.length ? j : 0;
                const coverImage = floorplan
                  ? floorplan
                  : i.Gallery?.ListOfPhotos[idx]?.Url || ""; // use random image from gallery if floorplan is missing
                const address = i.Data.Adresse.Value;
                // const prisValue = i.Data?.Husleje?.Value;
                const prisValue = i.Data?.Pris?.Value;
                const availablePris = i.Data?.Pris_Tekst_DK?.Value === "";
                const prisPost = i.Data?.Husleje?.Postfix;
                const pris = availablePris ? prisValue : "";
                prisValue === "-" ? prisValue : `${prisValue} ${prisPost}`;
                const groupArr = createGroupArr({ i, comparePropertyNames: x });
                const ColorCode = i.Data.UnitColor.Value;
                const projeknr = `${
                  buttonsLabel?.filter(
                    (btn) => btn?.UILabelProperty === "Bolignr"
                  )[0]?.Label
                } ${i.Data?.Projeknr?.Value}`;
                const townAndZipcode =
                  i?.Data?.ZipcodeID?.Value + " " + i?.Data?.By?.Value;
                const pricePf = i?.Data?.SalesTypeLejelejlighed?.Value
                  ? i?.Data?.LejelejlighedsText?.Value
                  : "";

                const clickHandler = () => {
                  if (comps.length < 2) navigateTo("/");
                  setComps(comps.filter((j) => j !== Number(i.UnitID)));
                };

                return (
                  <SwiperSlide
                    style={{ height: "fit-content", paddingBottom: 20 }}
                    key={j}
                  >
                    {/* <div style={{border:'1px solid black'}}>
                    <div>{ i.UnitID }</div>
                    <div>{ status }</div>
                    <div>{ address }</div>
                    <div>{ pris }</div>
                  </div>
                  <button onClick={clickHandler}>
                    REMOVE
                  </button> */}
                    <Box
                      sx={{
                        backgroundColor: `${ColorCode}22`,
                        marginBottom: 4,
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: ColorCode,
                          color: "#ffffff",
                          fontWeight: 700,
                          py: 2,
                          px: 2,
                        }}
                      >
                        {status}
                      </Box>
                      <Box>
                        <Box
                          component="img"
                          src={floorplan}
                          sx={{
                            width: "100%",
                          }}
                          alt="floorplan"
                        />
                      </Box>
                      <Box sx={{ p: "10px 18px" }}>
                        <Box
                          sx={{
                            position: "relative",
                            lineHeight: 1.5,
                            width: "100%",
                            flexGrow: 1,
                          }}
                        >
                          <Box sx={{ height: "96px" }}>
                            <div>{projeknr}</div>
                            <div>
                              {address}, {townAndZipcode}
                            </div>
                            <Box sx={{ fontWeight: 700 }}>
                              {pris}{" "}
                              {availablePris ? pricePf.toLowerCase() : ""}
                            </Box>
                          </Box>

                          <Divider sx={{ mt: 2, mb: 1 }} />

                          <Stack direction="row" justifyContent="space-between">
                            <ButtonShare
                              unitId={i.UnitID}
                              iconOnly={isMobile}
                            />
                            <ButtonCompare
                              unitId={i.UnitID}
                              iconOnly={isMobile}
                            />
                            <ButtonFavorite
                              unitId={i.UnitID}
                              iconOnly={isMobile}
                            />
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                    <div>
                      {i.Groups.map((x, y) => {
                        return (
                          <Box
                            key={y}
                            sx={{
                              pt: 3,
                              display: !groupArr.hasOwnProperty(x)
                                ? "none"
                                : "",
                            }}
                          >
                            {!j ? (
                              <Box
                                sx={{
                                  fontWeight: "bold",
                                  height: "20px",
                                  pb: 3,
                                  px: 3,
                                }}
                              >
                                {x}
                              </Box>
                            ) : (
                              <Box sx={{ height: "20px", pb: 3 }}></Box>
                            )}
                            {groupArr[x]?.map((item, index) => {
                              const value =
                                item.Value === true
                                  ? buttonsLabel?.filter(
                                      (btn) => btn.UILabelProperty === "Ja"
                                    )[0]?.Label
                                  : item.Value === false
                                  ? buttonsLabel?.filter(
                                      (btn) => btn.UILabelProperty === "Nej"
                                    )[0]?.Label
                                  : item.Value;
                              const label = item.Label;
                              const postfix = item.Postfix;

                              // if ( showDiff && !item.IsDifferent ) { return null }
                              if (item.ShowValue == false) {
                                return null;
                              }

                              return (
                                <Box
                                  key={index}
                                  sx={{
                                    borderRight: "1px solid rgba(0,0,0,0.05)",
                                  }}
                                >
                                  <Box>
                                    <Grid>
                                      <Grid item key={i} xs={4}>
                                        <Box
                                          sx={{
                                            py: 1.1,
                                            lineHeight: 1.5,
                                            backgroundColor: item?.IsDifferent
                                              ? "#f7f7f7"
                                              : "transparent",
                                            px: 3,
                                          }}
                                        >
                                          <Box sx={{ fontWeight: "bold" }}>
                                            {label}
                                          </Box>
                                          <Box>
                                            {value}
                                            {postfix}
                                          </Box>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  {/* <div>{label}</div> */}
                                  {/* <div style={{paddingLeft:20}}>
                                      <div style={{backgroundColor: item.IsDifferent ? 'red' : 'green'}}>
                                        {value || '-'}
                                        {postfix }
                                      </div>
                                    </div> */}
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      })}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        )}
        <PoweredBy />
      </Box>
    </>
  );
};

const SwiperWrapStyle = {
  width: "100%",
  height: "100%",
  overflow: "initial",
  userSelect: "none",
};

const SwiperProps = {
  spaceBetween: 1,
  modules: [FreeMode],
  freeMode: {
    enabled: true,
    sticky: true,
    momentumBounce: false,
    momentum: false,
  },
  // breakpoints: {
  //   580: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
  //   768: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
  //   1058: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
  //   1558: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
  // },
  slidesPerView: 3,
  style: SwiperWrapStyle,
  grabCursor: true,
  // centeredSlidesBounds: true,
  // centerInsufficientSlides: true,
  keyboard: { enabled: true, onlyInViewport: false },
  resistance: true,
  resistanceRatio: 0.0,
};

const createGroupArr = ({ i, comparePropertyNames }) => {
  const groupArr = comparePropertyNames.reduce((a, k) => {
    const itemData = i.Data[k.Property] ?? {
      Label: "-",
      Value: "",
      IsDifferent: k.Different,
    };
    const groupName = i.Data[k.Property]?.GroupName ?? k.Group;
    // if a doesn't contain current GroupName yet, add it!
    if (!a[groupName]) {
      a[groupName] = [];
    }
    // add current itemData to GroupName Array under certain conditions
    a[groupName].push(itemData);
    return a;
  }, {});
  return groupArr;
};

const comparePropertyNames = (units) => {
  let arr = [];
  units.forEach((unit) => {
    Object.keys(unit.Data).forEach((propertyName) => {
      const hasLabel = unit.Data[propertyName].Label;
      const isComparable = unit.Data[propertyName].Comparable;
      const shouldInclude = hasLabel && isComparable;
      const exists = arr.map((i) => i.Property).includes(propertyName);
      if (!exists && shouldInclude) {
        arr = [
          ...arr,
          {
            Property: propertyName,
            Label: unit.Data[propertyName].Label,
            Group: unit.Data[propertyName].GroupName,
            Different: unit.Data[propertyName].IsDifferent,
          },
        ];
      }
    });
  });
  return arr;
};
