import { useState, useEffect } from "react";
import { useAtom, get, useGet, getFocus } from "state/jotai";
import { _fullscreen, active_project } from "state/store.global";
import { Fullscreen } from "components/Fullscreen/Fullscreen";
import { MaximizedGallery } from "components/MaximizedGallery/MaximizedGallery";
import { StyledContainer } from "./Gallery.style";
import { StyledTabsContainer } from "./Gallery.style";
import { StyledTabButton } from "./Gallery.style";
import { StyledGridContainer } from "./Gallery.style";
import { StyledGrid } from "./Gallery.style";
import { StyledGridItem } from "./Gallery.style";
import { StyledImg } from "./Gallery.style";
import { _project } from "state/store.projects";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { Box } from "@mui/material";

export const Gallery = () => {
  const gallery = get(_project, "gallery");
  const photos = gallery?.Data?.ListOfPhotos ?? [];
  const tags = gallery?.Tags ?? [];

  const [, setIsFullscr] = useAtom(_fullscreen);
  const [activeTag, setActiveTag] = useState(null);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  useEffect(() => {
    setSelectedPhotos(
      photos.reduce(function (filtered, photo, i) {
        if (activeTag === null || photo.Tags.includes(activeTag)) {
          filtered.push({
            key: i,
            url: photo.Url,
            thumbnail: photo.UrlThumbnail,
            description: photo.Description,
          });
        }
        return filtered;
      }, [])
    );
  }, [activeTag, gallery]);

  const handleClickImage = (i) => {
    setSelectedIndex(i);
    setIsFullscr(true);
  };

  return (
    <>
      {/* tags */}
      <StyledTabsContainer>
        <StyledTabButton
          active={activeTag === null}
          onClick={() => setActiveTag(null)}
        >
          {
            buttonsLabel?.filter((btn) => btn.UILabelProperty === "Alle")[0]
              ?.Label
          }
        </StyledTabButton>
        {tags.map((tag, i) => {
          return (
            <StyledTabButton
              key={i}
              active={activeTag === tag}
              onClick={() => setActiveTag(tag)}
            >
              {
                buttonsLabel?.filter((btn) => btn.UILabelProperty === tag)[0]
                  ?.Label
              }
            </StyledTabButton>
          );
        })}
      </StyledTabsContainer>
      <StyledContainer>
        {/* grid / images */}
        <StyledGridContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "calc(100vh - 220px)",
              justifyContent: "space-between",
            }}
          >
            <StyledGrid>
              {selectedPhotos.map((photo, i) => {
                return (
                  <StyledGridItem key={photo.key}>
                    <StyledImg
                      src={photo.thumbnail}
                      alt={photo.description}
                      onClick={() => handleClickImage(i)}
                    />
                  </StyledGridItem>
                );
              })}
            </StyledGrid>
            <PoweredBy />
          </Box>
          <div style={{ height: "62px" }} />
        </StyledGridContainer>
      </StyledContainer>
      <Fullscreen>
        <MaximizedGallery initial={selectedIndex} images={selectedPhotos} />
      </Fullscreen>
    </>
  );
};
