import { useEffect } from "react";
import { useScalePoint } from "hooks/useScalePoint";
import { useBreak } from "hooks/useBreak";

export const createZppSetup = (bounds, ref, dimensions, setScale, mode) => {
  const isMobile = useBreak("md_dn");
  const scale = useScalePoint(bounds, dimensions, mode) + (isMobile ? 0 : 0);

  useEffect(() => {
    ref.current?.zoomToElement("center", scale, 0, null);
    // ref.current?.setTransform(-dimensions.w/2,0, scale, 0, null)
    setScale(scale ? scale : 1);
  }, [scale, bounds, mode]);

  const wrapperProps = {
    wheel: { step: 0.04 },
    limitToBounds: true,
    panning: { velocityDisabled: true },
    alignmentAnimation: { sizeX: 0, sizeY: 0 },
    zoomAnimation: { size: 0 },
    minScale: scale ?? 0,
    maxScale: scale ? 8 : 0,
    onInit: event => event.zoomToElement("center", scale, 0, null),
    onZoom: event => setScale(event.state.scale),
  };
  const wrapperStyle = {
    width: "100%",
    height: "100%",
    // cursor  : '',
    position: "absolute",
    // border  : '10px solid black'
  };
  const contentStyle = {
    width: dimensions.W,
    height: dimensions.H,
    // background: 'pink',
    // border : '10px solid orange'
  };
  return {
    wrapperProps,
    wrapperStyle,
    contentStyle,
  };
};
