import { Box, Grid } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import styled from "styled-components/macro";

function Highlights({ items }) {
  // const { pin, pid }    = useGet(active_project);
  // const highlightsLabel = getFocus(_project, "Highlights")?.[pin]?.[pid] ?? [];
  // console.log(items, "items");
  return (
    <>
      <HighlightBox className="box">
        <Container className="container">
          <Items className="item-1">
            {items.map((item, i) => {
              if (i % 2 === 0) {
                // For the even item
                return (
                  <div key={`item1-${i}`}>
                    <HighlightItem item={item} />
                  </div>
                );
              }
            })}
          </Items>
          <Items className="item-2">
            {items.map((item, i) => {
              if (i % 2 !== 0) {
                // For the odd item
                return (
                  <div key={`item2-${i}`}>
                    <HighlightItem item={item} />
                  </div>
                );
              }
            })}
          </Items>
        </Container>
      </HighlightBox>
    </>
  );
}

Highlights.propTypes = {
  items: PropTypes.array.isRequired,
};

function HighlightItem({ item }) {
  const { pin, pid } = useGet(active_project);
  const highlightsLabel = getFocus(_project, "Highlights")?.[pin]?.[pid] ?? [];
  return (
    <Box
      sx={{
        display: "flex",
        lineHeight: 1.3,
        alignItems: "center",
        my: 1,
      }}
    >
      <Box
        sx={{
          width: 45,
          flex: "0 0 45px",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Icon
          icon={item?.icon}
          sx={{
            fontSize: 30,
          }}
        />
      </Box>
      <div>
        <div>
          {
            highlightsLabel?.filter(
              highlight => highlight.Property === item.property
            )[0]?.Label
          }
        </div>
        <strong>
          {item?.value} {item?.postfix}
        </strong>
      </div>
    </Box>
  );
}

HighlightItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Highlights;

const HighlightBox = styled.div`
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 50%;
`;
