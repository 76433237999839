import styled from "styled-components/macro";
import { BREAK_ } from "constants";

export const Figure = styled.figure`
  position: absolute;
  ${BREAK_.md_up} {
    top: ${({ rmode }) =>
      rmode ? "calc(100% - 275px)" : "calc(100% - 205px)"};
    right: ${({ rmode }) => (rmode ? 120 : 40)}px;
  }
  width: 32px;
  height: 32px;
  box-shadow: 10px;
  font-size: 15px;
  ${BREAK_.md_dn} {
    font-size: 17px;
    top: ${({ rmode }) => (rmode ? "71px" : "calc(100% - 140px)")};
    right: ${({ rmode }) => (rmode ? 15 : 20)}px;
    width: 26px;
    height: 26px;
  }
  /* border: 1px solid black; */
`;
export const Text = styled.text`
  /* font-family: serif; */
  font-size: 20px;
  font-weight: 900;
  fill: #000;
  /* transform-box: fill-box; // not working on safari; instead use rotate(angle, cx, cy)*/
  /* transform-origin: center; // not working on safari; instead use rotate(angle, cx, cy)*/
`;
export const Group = styled.g``;
export const Line = styled.line`
  stroke: #000;
  stroke-width: 5px;
`;
export const Circle = styled.circle`
  fill: none;
  stroke: #000;
  stroke-width: 2;
`;
export const Svg = styled.svg``;

export const Label = styled.div`
  position: absolute;
  right: 35px;
  top: 13px;
  transform: translatey(-50%);
  white-space: nowrap;
`;
