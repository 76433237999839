import styled from "styled-components/macro";
import { BREAK_ } from "constants";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ sx }) => sx}
`;

export const CTA = styled.div`
  display: flex;
  line-height: 1;
  gap: 5px;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
`;

export const Low = styled.div`
  display: flex;
  font-size: 1.0625rem;
  gap: 30px;
  justify-content: ${({ type }) =>
    type == "false" ? "space-between" : "flex-start"};
  font-weight: 600;
  align-items: center;
  line-height: 2;
  padding-left: 12px;
  padding-right: 12px;
  height: 32px;
`;

export const Separator = styled.div`
  width: calc(100% - 10px);
  margin-left: 10px;
  min-height: 1px;
  background: ${({ color }) => `${color}22`};
  ${BREAK_.md_dn} {
    width: calc(100% - 24px);
    margin: 0 auto;
  }
  height: 1px;
`;

export const Status = styled.div`
  font-weight: 600;
  color: ${({ color }) => color};
  font-size: 1rem;
  position: absolute;
  top: 11px;
  right: 20px;
  ${BREAK_.md_dn} {
    font-size: 0.95rem;
    // top:18px;
    right: 15px;
  }
`;
