import React from "react";
import Favorites from "components/Favorites/Favorites";
import { Box } from "@mui/material";

export const ViewFavorites = () => {
  return (
    <>
      <Box sx={{ pb: { xs: "0px", md: "62px" } }}>
        <Favorites />
      </Box>
    </>
  );
};
