const palette = {
  primary: {
    main: "#D4C78C",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#2F5D62",
    contastText: "#FFFFFF",
  },
  text: {
    primary: "#000000",
  },
  success: {
    main: "#0E912C",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
};

export default palette;
